import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "services/store";
import StyledLanding from "./styled";
import FullStory from "react-fullstory";
import {FULLSTORY_ORG_ID} from "../../utils/axios_helper";
import videoIcon  from "assets/auth/video.svg";
import checkFill from "assets/auth/Check_fill.png";
import vatic_logo from "../../assets/VaticLogo/Vatic Logo_All_Black.svg";
 

const Landing = () => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => (state.login.is_authenticated));

    const redirect = (path: string) => {
        navigate(path);
    }

    useEffect(() => {
        if (isAuthenticated) {
            redirect('/dashboard');
        }
    }, [])

    return (
        <StyledLanding>
            <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <div className="btn-ctn">
                        <button className="header-login-btn" onClick={() => navigate('/auth/login')}>
                            Login
                        </button>
                        <button className="header-signup-btn" onClick={() => navigate("/auth/signup")}>Sign Up</button>
                    </div>
                </div>
            </div>

            <div className="video-ctn">
                <img src={videoIcon} alt=''/>
            </div>

            <div className="lst-ctn">
                <div className="heading">QScore Audience as a Service</div>
                <div>Create precise campaign audience segments in a few clicks utilizing our predictive financial
                    qualification capabilities.
                </div>

                <div className="btn">
                    Get Started
                </div>

                <div className="list-ctn">
                    <div className="opt-item">
                        <img src={checkFill} alt='' className="icon"/>
                        <div className="opt">Generate pre-qualified financial audiences</div>
                    </div>

                    <div className="opt-item">
                        <img src={checkFill} alt='' className="icon"/>
                        <div className="opt">Enhance Campaign Results</div>
                    </div>

                    <div className="opt-item">
                        <img src={checkFill} alt='' className="icon"/>
                        <div className="opt">Maintain full control over your strategies</div>
                    </div>

                    <div className="opt-item">
                        <img src={checkFill} alt='' className="icon"/>
                        <div className="opt">Multichannel Integrations</div>
                    </div>
                </div>

            </div>

            <div className="privacy m-top hide-icon"><span className='privacy-text'
                                                           onClick={() => redirect('/privacy')}>PRIVACY POLICY</span>
            </div>
            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledLanding>
    )
}

export default Landing;