import React from "react";

import StyledEmailVerification from "./styled";
import messageIcon from 'assets/auth/Message_alt_fill-2.png';
import leftArrow from "../../../assets/auth/Arrow_left.png";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "services/store";
import {vaticAxiosPost} from "../../../utils/axios_helper";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg"


const EmailVerification = () => {
    const navigate = useNavigate();
    const data = useSelector((state: RootState) => ({type: state.signup.verificationType, verify_email: state.signup.verify_email, email: state.signup.email, firstName: state.signup.first_name}));

    const getVerificationLink = async () => {
        if(data.type == 1) {
            const token = localStorage.getItem('ver_token') || '';
            const res = await vaticAxiosPost("/api/v1/email-verification-repeat", {
                first_name: data.firstName,
                email: data.email,
                token: token,
            });
            if (res.statusCode == 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                toast.success('Please verify your email', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            } else {
                toast.error(`${res.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                })
            }
        } else {
            const res = await vaticAxiosPost('/api/auth/v1/reset-password-send', {
                'email': data.verify_email
            });

            if(res.statusCode == 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        }
    }

    return (
        <StyledEmailVerification>
             <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <button className="header-login-btn" onClick={() => navigate("/auth/login")}>
                        Login
                    </button>
                </div>
            </div>
            <div className="ctn ctn-override">
                <div className="inner-ctn">
                    <div className="msg-ctn">
                        <img src={messageIcon} alt='' className="msg"/>
                    </div>
                    <div className="heading m-top">Email Verification</div>
                    <div className="txt m-top">We have sent an email to “{data.verify_email}” for verification.</div>
                    <div className="login-btn m-top" onClick={getVerificationLink}>
                        Didn’t receive email yet?
                    </div>
                    <div className="back m-top">
                        <img src={leftArrow} alt='' className="google-icon"/><span className="forget"
                                                                                   onClick={() => navigate("/auth/login")}>Back to Login</span>
                    </div>
                </div>
            </div>
        </StyledEmailVerification>
    );
}

export default EmailVerification;