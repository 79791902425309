import React from "react";
import StyledAuth from "./styled"
import { Route, Routes } from "react-router";
import Signup from "components/Auth/Signup";
import Login from "components/Auth/Login";
import ForgotForm from "components/Auth/ForgotPassword";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID } from "../../utils/axios_helper";
import ResetPassword from "./ResetPassword";
import bg from "assets/background-theme/bg-auth-desktop.svg"
import ForgotPassword from "components/Auth/ForgotPassword";
import EmailVerification from "./EmailVerification";

interface AuthInterface {}

const Auth: React.FunctionComponent<AuthInterface> = ({}) => {
  return (
    <StyledAuth>
      <Routes>
        <Route path="signup" element={<Signup />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="forgot-password" element={<ForgotForm />}></Route>
        <Route path="password-reset" element={<ResetPassword />}></Route>
        <Route path="verification" element={<EmailVerification />}></Route>
      </Routes>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAuth>
  );
};

export default Auth;
