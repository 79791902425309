import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";
import {set, setVerify} from "services/reducer/signup";
import {
    FULLSTORY_ORG_ID,
    getGoogleUserInfo,
    googleSignup,
    vaticAxiosPost,
} from "utils/axios_helper";
import StyledSignup from "./styled";
import queryString from "query-string";
import validator from "validator";
import FullStory from "react-fullstory";
import business from "assets/auth/Shop.png";
import profile from "assets/auth/User.png";
import message from "assets/auth/Message.png";
import password_icon from "assets/auth/lock.png";
import hideEyeLight from "assets/auth/hide_eye_light.png";
import google_logo from "assets/auth/google-icon.png";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg"

const SignupForm: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false); // State for checkbox
    const [dataFetched, setDataFetched] = useState<boolean>(false);
    const [fetchedData, setFetchedData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        img_url: "",
        access_token: "",
        company_name: "",
        condition: true,
    });

    // State variables for error messages
    const [errors, setErrors] = useState({
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        checkbox: "",
    });

    const [errors1, setErrors1] = useState({
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        condition: "",
    });

    const [showPassword, setShowPassword] = useState(false);

    const dataFromGoogle = async (access_token: string) => {
        const data = await getGoogleUserInfo(access_token);

        if (data) {
            setDataFetched(true);
            setFetchedData({
                first_name: data.given_name,
                last_name: data.family_name,
                email: data.email,
                img_url: data.picture,
                access_token: access_token,
                company_name: "",
                condition: true,
            });
        } else {
            toast.error("Unable to sign up using google account", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    };

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.hash);
        const accessToken = Array.isArray(parsedHash["access_token"])
            ? parsedHash["access_token"][0]
            : parsedHash["access_token"];
        const referToken = Array.isArray(parsedHash["token"])
            ? parsedHash["token"][0]
            : parsedHash["token"];

        if (referToken) {
            localStorage.setItem("refer_token", referToken);
        }
        if (accessToken) {
            dataFromGoogle(accessToken);
        }
    }, []);

    // Validation function to check if any field is left empty
    const validateForm = () => {
        let valid = true;
        const newErrors = {
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            checkbox: "",
        };

        if (!companyName) {
            newErrors.companyName = "Company name is required";
            valid = false;
        }
        if (!firstName) {
            newErrors.firstName = "First name is required";
            valid = false;
        }
        if (!lastName) {
            newErrors.lastName = "Last name is required";
            valid = false;
        }
        if (!email) {
            newErrors.email = "Email is required";
            valid = false;
        } else if (!validator.isEmail(email)) {
            newErrors.email = "Please enter a valid Email";
            valid = false;
        }
        if (!password) {
            newErrors.password = "Password is required";
            valid = false;
        } else {
            const minLength = validator.isLength(password, {min: 8});
            const hasNumbers = validator.matches(password, /\d/);
            const hasUppercase = validator.matches(password, /[A-Z]/);
            const hasSpecialChars = validator.matches(password, /[^A-Za-z0-9]/);

            if (!minLength || !hasNumbers || !hasUppercase || !hasSpecialChars) {
                newErrors.password =
                    "Your password must be at least 8 characters long, include at least one uppercase letter, one number, and one special character.";
                valid = false;
            }
        }
        if (!isChecked) {
            newErrors.checkbox = "Please agree to the terms";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const signupDetails = async () => {
        // Validate form before continuing
        const isValid = validateForm();

        // Proceed with signup if the form is valid
        if (isValid) {
            const referToken = localStorage.getItem('refer_token') || '';
            const res = await vaticAxiosPost("/api/auth/v1/register", {
                company_name: companyName,
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                refer_token: referToken});
            if (res.statusCode == 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                toast.success('Please verify your email', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                localStorage.setItem("ver_token", res.data.token);
                dispatch(set({email: email, company_name: companyName, first_name: firstName, last_name: lastName, password: password}));
                dispatch(setVerify({verificationType: 1, verify_email: email}))
                navigate('/auth/verification');
            } else {
                toast.error(`${res.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000
                })
            }

            setIsChecked(true);
        }
    };

    const validateForm1 = () => {
        let valid = true;
        const newErrors = {
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            condition: "",
        };

        if (!fetchedData.company_name) {
            newErrors.companyName = "Company name is required";
            valid = false;
        }
        if (!fetchedData.first_name) {
            newErrors.firstName = "First name is required";
            valid = false;
        }
        if (!fetchedData.last_name) {
            newErrors.lastName = "Last name is required";
            valid = false;
        }
        if (!fetchedData.condition) {
            newErrors.condition = "Please agree to the terms";
            valid = false;
        }

        setErrors1(newErrors);
        return valid;
    };

    const handleGoogleSignup = async () => {
        const valid = validateForm1();
        if (!valid) {
            return;
        }
        const referToken = localStorage.getItem("refer_token") || "";
        const res = await vaticAxiosPost("/api/auth/v1/register-by-google", {
            first_name: fetchedData.first_name,
            last_name: fetchedData.last_name,
            email: fetchedData.email,
            img_url: fetchedData.img_url,
            access_token: fetchedData.access_token,
            company_name: fetchedData.company_name,
            refer_token: referToken,
        });
        if (res.statusCode == 200) {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            navigate("/auth/login");
        } else {
            toast.error(`${res.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    };

    const [passwordStrength, setPasswordStrength] = useState(0);
    const calculatePasswordStrength = (password: string) => {
        let strength = 0;

        if (password.length > 5) strength += 1;  // Basic length requirement
        if (password.length > 8) strength += 1;  // Good length
        if (/[A-Z]/.test(password)) strength += 1;  // Contains uppercase letter
        if (/[0-9]/.test(password)) strength += 1;  // Contains a digit
        if (/[^A-Za-z0-9]/.test(password)) strength += 1;  // Contains special character

        setPasswordStrength(strength);
    };

    return (
        <StyledSignup>
            <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <button className="header-login-btn" onClick={()=> navigate('/auth/login')}>
                        Login
                    </button>
                </div>
            </div>
            {!dataFetched ? (
                <div className="ctn ctn-override">
                    <div className="inner-ctn">
                        <div className="heading">Welcome to VaticAI</div>
                        <div className="desc">
                            Please sign up by filling the form or use SSO.
                        </div>

                        <div className="label m-top">
                            Company name *
                        </div>
                        <div className="input-ctn">
                            <img src={business} alt=''></img>
                            <input
                                className='email-input'
                                placeholder="Your company name"
                                type="text"
                                id="company_name"
                                name="company_name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                        {errors.companyName && (
                            <span className="error">{errors.companyName}</span>
                        )}

                        <div className="label m-top">
                            First Name *
                        </div>
                        <div className="input-ctn">
                            <img src={profile} alt=""></img>
                            <input
                                className='email-input'
                                placeholder="First Name"
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        {errors.firstName && (
                            <span className="error">{errors.firstName}</span>
                        )}

                        <div className="label m-top">
                            Last Name *
                        </div>
                        <div className="input-ctn">
                            <img src={profile} alt=""></img>
                            <input
                                className=' email-input'
                                placeholder="Last Name"
                                type="text"
                                id="last_name"
                                name="last_name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        {errors.lastName && (
                            <span className="error">{errors.lastName}</span>
                        )}

                        <div className="label m-top">
                            Email *
                        </div>
                        <div className="input-ctn">
                            <img src={message} alt=''/>
                            <input
                                className=' email-input'
                                placeholder="Enter your email"
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {errors.email && (
                            <span className="error">{errors.email}</span>
                        )}


                        <div className="label m-top">
                            Password *
                        </div>
                        <div className="input-ctn">
                            <img src={password_icon} alt=''/>
                            <input
                                className='pass-input'
                                placeholder="Create a password"
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => {setPassword(e.target.value); calculatePasswordStrength(e.target.value);}}
                            />
                            <img src={hideEyeLight} alt={''} onClick={() => setShowPassword(prev => !prev)}
                                 className="hide-icon"/>
                        </div>
                        {
                            password.length > 0 && (
                                <div className="strength-ctn">
                                    <div className="progress-ctn">
                                        <div className={passwordStrength <= 2 ? "bar-weak": passwordStrength <= 4 ? "bar-medium": "bar-strong"}></div>
                                        <div className={passwordStrength <= 4 && passwordStrength > 2 ? "bar-medium": passwordStrength > 4 ? "bar-strong": ""}></div>
                                        <div className={passwordStrength >= 5 ? "bar-strong": ""}></div>
                                    </div>
                                    <div className="progress-text-ctn">
                                        <div className="desc">Password Strength:</div>
                                        {
                                            <div className={passwordStrength <= 2 ? "desc text-weak": passwordStrength <= 4 ? "desc text-medium": "desc text-strong"} >
                                                {
                                                    passwordStrength <= 2 ? "Weak": passwordStrength <= 4 ? "Medium": "Strong"
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {errors.password && (
                            <span className="error">{errors.password}</span>
                        )}

                        <div className="tnc-container">
                            <input
                                className="checkbox tnc-checkbox"
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                            ></input>
                            <div className="label m-top">
                                By creating an account you agree with our {" "}
                                <span className="forget">
                                  <a
                                      href="https://audience.vaticai.com/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                    Terms of Service & Privacy Policy.
                                  </a>
                                </span>
                            </div>
                        </div>
                        {errors.checkbox && (
                            <div className="error">{errors.checkbox}</div>
                        )}

                        <div className="login-btn m-top" onClick={signupDetails}>
                            Sign Up
                        </div>

                        <div
                            className="google-signin m-top"
                            onClick={googleSignup}
                        >
                            <img
                                className="google-icon"
                                alt="google-login"
                                src={google_logo}
                            />
                            <div className="google-login-text">Sign up with Google</div>
                        </div>

                        <div className="signup-text m-top">
                            Already have an account? {" "}
                            <span
                                className="forget"
                                onClick={() => navigate("/auth/login")}
                            >
                            Log in
                          </span>
                        </div>
                        <div className="m-top hide-icon txt-center">
                          <span
                            className="privacy-text"
                            onClick={() => navigate("/privacy")}
                          >
                            PRIVACY POLICY
                          </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="ctn ctn-override">
                    <div className="inner-ctn">
                        <div className="heading">Welcome to VaticAI</div>
                        <div className="desc">
                            Please sign up by filling the form or use SSO.
                        </div>
                        <div className="label m-top">
                            Company name *
                        </div>
                        <div className="input-ctn">
                            <img src={business} alt=''></img>
                            <input
                                className='email-input'
                                placeholder="Your company name"
                                type="text"
                                id="company_name"
                                name="company_name"
                                value={fetchedData.company_name}
                                onChange={(e) =>
                                    setFetchedData((prev) => {
                                        return {...prev, company_name: e.target.value};
                                    })
                                }
                            />
                        </div>
                        {errors1.companyName && (
                            <span className="error">{errors1.companyName}</span>
                        )}

                        <div className="label m-top">
                            First Name *
                        </div>
                        <div className="input-ctn">
                            <img src={profile} alt=""></img>
                            <input
                                className='email-input'
                                placeholder="First Name"
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={fetchedData.first_name}
                                onChange={(e) =>
                                    setFetchedData((prev) => {
                                        return {...prev, first_name: e.target.value};
                                    })
                                }
                            />
                        </div>
                        {errors1.firstName && (
                            <span className="error">{errors1.firstName}</span>
                        )}

                        <div className="label m-top">
                            Last Name *
                        </div>
                        <div className="input-ctn">
                            <img src={profile} alt=""></img>
                            <input
                                className=' email-input'
                                placeholder="Last Name"
                                type="text"
                                id="last_name"
                                name="last_name"
                                value={fetchedData.last_name}
                                onChange={(e) =>
                                    setFetchedData((prev) => {
                                        return {...prev, last_name: e.target.value};
                                    })
                                }
                            />
                        </div>
                        {errors1.lastName && (
                            <span className="error">{errors1.lastName}</span>
                        )}

                        <div className="label m-top">
                            Email *
                        </div>
                        <div className="input-ctn">
                            <img src={message} alt=''/>
                            <input
                                className=' email-input'
                                placeholder="Enter Your email"
                                type="email"
                                id="email"
                                name="email"
                                value={fetchedData.email}
                                disabled
                            />
                        </div>

                        <div className="tnc-container">
                            <input
                                className="tnc-checkbox"
                                type="checkbox"
                                checked={fetchedData.condition}
                                onChange={() =>
                                    setFetchedData((prev) => {
                                        return {...prev, condition: !prev.condition};
                                    })
                                }
                            ></input>
                            <div className="by-creating-an-container">
                                By creating an account you agree with our {" "}
                                <span className="forget">
                                  <a
                                      href="https://audience.vaticai.com/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                    Terms of Service, Privacy Policy.
                                  </a>
                                </span>
                            </div>
                        </div>
                        {errors1.condition && (
                            <div className="error">{errors1.condition}</div>
                        )}

                        <div className="login-btn m-top" onClick={handleGoogleSignup}>
                            Create Account
                        </div>

                        <div className="m-top hide-icon txt-center">
                          <span
                              className="privacy-text"
                              onClick={() => navigate("/privacy")}
                          >
                            PRIVACY POLICY
                          </span>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <FullStory org={FULLSTORY_ORG_ID || ""}/>
            </div>
        </StyledSignup>
    );
};

export default SignupForm;
