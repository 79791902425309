import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./reducer/login";
import signupReducer from "./reducer/signup";
import platform from "./reducer/platform";
import home from "./reducer/home";
import audience from "./reducer/audience";
import estimate from "./reducer/estimate";
import callibrate from "./reducer/callibrate";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    signup: signupReducer,
    platform: platform,
    home: home,
    audience: audience,
    estimate: estimate,
    callibrate: callibrate,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
