import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface SignupState {
    company_name: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;

    verificationType: number
    verify_email: string
}


const initialState: SignupState = {
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',

    verificationType: 1,
    verify_email: '',
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<Partial<SignupState>>) => {
            return {
                ...state,
                ...action.payload
            }
        },

        unset: (state) => {
            return {
                ...state,
                company_name : '',
                first_name : '',
                last_name : '',
                email : '',
                password : '',
            }
        },

        setVerify: (state, action: PayloadAction<Partial<SignupState>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },

        unsetVerify: (state) => {
            state.verify_email = '';
            state.verificationType = 1;
        },
    },
})

export const { set, unset, setVerify, unsetVerify } = signupSlice.actions
export default signupSlice.reducer