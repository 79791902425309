import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export interface CallibrateState {
  estimate_id: number;
  estimate_name: string;
  is_vatic: boolean;
  qscore: number;
  recency: number;
  frequency: number;
  similarity: number;
  intent: number;
  initAbsoluteDiff: { [key: number | string]: { id: number, value: number }[] },
  initRelativeDiff: { [key: number | string]: { id: number, value: number }[] },
}

const temp = localStorage.getItem("callibrate_page") || "{}";
let obj = JSON.parse(temp);

const initialState: CallibrateState = {
  estimate_id: obj.estimate_id || -1,
  estimate_name: obj.estimate_name || "",
  is_vatic: obj.is_vatic,
  qscore: obj.qscore || 0,
  recency: obj.recency || 0,
  frequency: obj.frequency || 0,
  similarity: obj.similarity || 0,
  intent: obj.intent || 0,
  initAbsoluteDiff: obj.initAbsoluteDiff || {},
  initRelativeDiff: obj.initRelativeDiff || {},
};

export const callibrateSlice = createSlice({
  name: "callibrate",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<CallibrateState>>) => {
      const t0 = JSON.parse(localStorage.getItem("callibrate_page") || "{}");

      localStorage.setItem(
        "callibrate_page",
        JSON.stringify({
          ...t0,
          ...action.payload,
        })
      );

      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      try {
        localStorage.removeItem("callibrate_page");
      } catch {
        console.log("error");
      }
      const new_initial_state: CallibrateState = {
        estimate_id: -1,
        estimate_name: "Select Signal",
        is_vatic: true,
        qscore: 0,
        recency: 0,
        frequency: 0,
        similarity: 0,
        intent: 0,
        initAbsoluteDiff: {},
        initRelativeDiff: {},
      };

      return {
        ...new_initial_state,
      };
    },
  },
});

export const { set, unset } = callibrateSlice.actions;
export default callibrateSlice.reducer;
