import google_logo from "assets/auth/google-icon.png";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set } from "../../../services/reducer/login";
import { set as platformSet } from "services/reducer/platform";
import {
  FULLSTORY_ORG_ID,
  getGoogleUserInfo,
  googleSignin,
  vaticAxiosGet,
  vaticAxiosPost,
} from "utils/axios_helper";
import StyledLogin from "./styled";
import FullStory from "react-fullstory";
import messageIcon from "assets/auth/Message.png";
import lockIcon from "assets/auth/lock.png";
import hideEyeLight from "assets/auth/hide_eye_light.png";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg"

interface LoginInterface {}

const Login: React.FunctionComponent<LoginInterface> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    if (!email) {
      newErrors.email = "Please check if your email address is correct.";
      valid = false;
    }
    if (!password) {
      newErrors.password = "Please enter correct password.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const dataFromGoogle = async (access_token: string) => {
    const data = await getGoogleUserInfo(access_token);
    const userEmail = data.email;
    const userFirstName = data.given_name;
    if (userEmail) {
      const userInfo = await vaticAxiosPost("/api/auth/v1/login-by-google", {
        email: userEmail,
      });
      if (userInfo && userInfo.statusCode == 200) {
        dispatch(
          set({
            user_id: userInfo.data.user_id,
            access_token: userInfo.data.accessToken,
            is_authenticated: true,
            first_name: userFirstName,
          })
        );
        toast.success(userInfo.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/dashboard");
      } else {
        toast.error(`${userInfo.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } else {
      toast.error("Unable to log in using google account", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    const parsedHash = queryString.parse(window.location.hash);
    const accessToken = Array.isArray(parsedHash["access_token"])
      ? parsedHash["access_token"][0]
      : parsedHash["access_token"];
    if (accessToken) {
      dataFromGoogle(accessToken);
    }
  }, []);

  const authenticate = async () => {
    const isValid = validateForm();

    if (isValid) {
      const res = await vaticAxiosPost("/api/auth/v1/login", {
        email,
        password,
      });
      if (res && res.statusCode === 200) {
        dispatch(
          set({
            user_id: res.data.user_id,
            access_token: res.data.accessToken,
            is_authenticated: true,
            first_name: res.data.user_id,
          })
        );
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/dashboard");
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <StyledLogin>
      <div className="header">
        <div className="header-inner-ctn">
          <div>
            <img className="header-logo" src={vatic_logo}></img>
          </div>
          <button className="header-signup-btn" onClick={() => navigate("/auth/signup")}>Sign Up</button>
        </div>
      </div>
      <div className="ctn">
        <div className="inner-ctn">
          <div className="heading">Welcome Back!</div>
          <div className="desc">
            Please login with your Email Address and Password or use SSO.
          </div>
          <div className="label m-top">Email Address</div>
          <div className="input-ctn">
            <img src={messageIcon} alt={""} />
            <input
              className="email-input"
              type="email"
              placeholder="Enter email address"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {errors.email && <div className="error">{errors.email}</div>}

          <div className="label m-top">Password</div>
          <div className="input-ctn">
            <img src={lockIcon} alt={""} />
            <input
              className="pass-input"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              src={hideEyeLight}
              alt={""}
              onClick={() => setShowPassword((prev) => !prev)}
              className="hide-icon"
            />
          </div>
          {errors.password && <div className="error">{errors.password}</div>}

          <div className="action m-top">
            <div className="checkbox-ctn">
              <input type="checkbox" className="checkbox" />
              <div>Remember me</div>
            </div>
            <div
              className="forget"
              onClick={() => navigate("/auth/forgot-password")}
            >
              Forgot Password
            </div>
          </div>

          <div className="login-btn m-top" onClick={authenticate}>
            Login
          </div>

          <div className="google-signin m-top" onClick={googleSignin}>
            <img className="google-icon" alt="google-login" src={google_logo} />
            <div className="google-login-text">Sign in with Google</div>
          </div>

          <div className="signup-text m-top">
            Don’t have an account?{" "}
            <span className="forget" onClick={() => navigate("/auth/signup")}>
              Sign Up
            </span>
          </div>
        </div>
        <div className="m-top hide-icon">
          <span className="privacy-text" onClick={() => navigate("/privacy")}>
            PRIVACY POLICY
          </span>
        </div>
      </div>

      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledLogin>
  );
};

export default Login;
