import React, { useEffect, useState } from "react";

import StyledBilling from "./styled";
import { useSelector } from "react-redux";
import { RootState } from "services/store";
import { vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IBilling {
    setHeading: (val: string[]) => void;
}

const Billing: React.FunctionComponent<IBilling> = ({setHeading}) => {
    const country = useSelector((state: RootState) => state.home.country);

    const [selectedPlan, setSelectedPlan] = useState(1);

    const handleCheckout = async (type: number) => {
        const response = await vaticAxiosPost('/api/v1/payment/url', {type: type});
        if (response.statusCode === 200) {
            window.location.href = response.data;
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    }

    useEffect(() => {
        setHeading(["Billing", country.charAt(0) + country.substring(1).toLowerCase()]);
    }, []);
    return (
        <StyledBilling>
            <div className="plan-ctn">
                <div className={selectedPlan === 1 ? "plan-item plan-item-active": "plan-item"} onClick={() => setSelectedPlan(1)}>
                    <div className="plan-name">Basic Plan</div>
                    <div className="plan-font">Description 1</div>
                    <div className="plan-font">Description 2</div>
                    <div className="plan-font">Description 3</div>
                    <div><span className="plan-price">$10 </span><span className="plan-font">/month</span></div>
                    <div className="plan-btn" onClick={() => {handleCheckout(1)}}>Start</div>
                </div>

                <div className={selectedPlan === 2 ? "plan-item plan-item-active": "plan-item"} onClick={() => setSelectedPlan(2)}>
                    <div className="plan-name">Advance Plan</div>
                    <div className="plan-font">Description 1</div>
                    <div className="plan-font">Description 2</div>
                    <div className="plan-font">Description 3</div>
                    <div><span className="plan-price">$20 </span><span className="plan-font">/month</span></div>
                    <div className="plan-btn" onClick={() => {handleCheckout(2)}}>Start</div>
                </div>

                <div className={selectedPlan === 3 ? "plan-item plan-item-active": "plan-item"} onClick={() => setSelectedPlan(3)}>
                    <div className="plan-name">Enterprise Plan</div>
                    <div className="plan-font">Description 1</div>
                    <div className="plan-font">Description 2</div>
                    <div className="plan-font">Description 3</div>
                    <div><span className="plan-price">$30 </span><span className="plan-font">/month</span></div>
                    <div className="plan-btn" onClick={() => {handleCheckout(3)}}>Start</div>
                </div>
            </div>
        </StyledBilling>
    )
}

export default Billing;