import React, {useEffect, useState} from "react";
import StyledResetPassword from "./styled";
import queryString from "query-string";
import {FULLSTORY_ORG_ID, vaticAxiosGet, vaticAxiosPost} from "../../../utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router";
import validator from "validator";
import lockIcon from "../../../assets/auth/lock.png";
import hideEyeLight from "../../../assets/auth/hide_eye_light.png";
import FullStory from "react-fullstory";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg"

const ResetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState<{pass: string, confirmPass: string, email: string, token: string}>({pass: '', confirmPass: '', email: '', token: ''});
    const [errors, setErrors] = useState<{pass: string, confirmPass: string, msg: string}>({pass: '', confirmPass: '', msg: ''});
    const [showPassword, setShowPassword] = useState({pass: false, confirmPass: false});
    const [passwordStrength, setPasswordStrength] = useState(0);

    const handleReset = async () => {
        const minLength = validator.isLength(password.pass, {min: 8});
        const hasNumbers = validator.matches(password.pass, /\d/);
        const hasUppercase = validator.matches(password.pass, /[A-Z]/);
        const hasSpecialChars = validator.matches(password.pass, /[^A-Za-z0-9]/);

        if (!minLength || !hasNumbers || !hasUppercase || !hasSpecialChars) {
            setErrors((prev) => { return {...prev, msg:
                "Password must be at least 8 Characters and contain1 Number, 1 Uppercase, 1 Lowercase and 1 Symbol."}});
        } else if (password.pass != password.confirmPass) {
            setErrors((prev) => {return {...prev, confirmPass: "Both password must match", pass: 'Both password must match'}});
        } else {
            const res = await vaticAxiosPost("/api/auth/v1/reset-password", {
                email: password.email,
                token: password.token,
                password: password.pass
            });
            if (res.statusCode === 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                navigate("/auth/login");
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        }
    }

    const calculatePasswordStrength = (password: string) => {
        let strength = 0;

        if (password.length > 5) strength += 1;  // Basic length requirement
        if (password.length > 8) strength += 1;  // Good length
        if (/[A-Z]/.test(password)) strength += 1;  // Contains uppercase letter
        if (/[0-9]/.test(password)) strength += 1;  // Contains a digit
        if (/[^A-Za-z0-9]/.test(password)) strength += 1;  // Contains special character

        setPasswordStrength(strength);
    };

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.search);
        const token = Array.isArray(parsedHash["token"])
            ? parsedHash["token"][0]
            : parsedHash["token"];
        const email = Array.isArray(parsedHash["email"])
            ? parsedHash["email"][0]
            : parsedHash["email"];
        if (!token || !email) {
            toast.success('Invalid Request', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        } else {
            const load = async () => {
                const res = await vaticAxiosGet('/api/auth/v1/is-valid-pass-reset-req', {
                    token: token,
                    email: email
                });
                if (res.is_valid) {
                    setPassword((prev) => {return {...prev, email: email, token: token}});
                } else if(res.is_valid === false) {
                    toast.error(`Unable to Reset your password , Either this link has been expired or Invalid`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    });
                    navigate('/auth/login');
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    })
                }
            }
            load();
        }

    }, []);
    return (
        <StyledResetPassword>
             <div className="header">
                <div className="header-inner-ctn">
                    <div>
                        <img className="header-logo" src={vatic_logo}></img>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="ctn">
                <div className="inner-ctn">
                    <div className="heading">Create New Password</div>
                    <div className="desc">Your new password must be different from previous password</div>
                    <div className="label m-top">New Password</div>
                    <div className="input-ctn">
                        <img src={lockIcon} alt={''}/>
                        <input className="pass-input"
                               type={showPassword.pass ? "text" : "password"}
                               placeholder="Enter password"
                               id="password"
                               name="password"
                               value={password.pass}
                               onChange={(e) => {
                                   setPassword((prev) => {
                                       return {...prev, pass: e.target.value}
                                   });
                                   setErrors({pass: '', confirmPass: '', msg: ''});
                                   calculatePasswordStrength(e.target.value);
                               }}
                        />
                        <img src={hideEyeLight} alt={''} onClick={() => setShowPassword(prev => {
                            return {...prev, pass: !prev.pass}
                        })}
                             className="hide-icon"/>
                    </div>
                    {
                        password.pass.length > 0 && (
                            <div className="strength-ctn">
                                <div className="progress-ctn">
                                    <div className={passwordStrength <= 2 ? "bar-weak": passwordStrength <= 4 ? "bar-medium": "bar-strong"}></div>
                                    <div className={passwordStrength <= 4 && passwordStrength > 2 ? "bar-medium": passwordStrength > 4 ? "bar-strong": ""}></div>
                                    <div className={passwordStrength >= 5 ? "bar-strong": ""}></div>
                                </div>
                                <div className="progress-text-ctn">
                                    <div className="desc">Password Strength:</div>
                                    {
                                        <div className={passwordStrength <= 2 ? "desc text-weak": passwordStrength <= 4 ? "desc text-medium": "desc text-strong"} >
                                            {
                                                passwordStrength <= 2 ? "Weak": passwordStrength <= 4 ? "Medium": "Strong"
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    {errors.pass && (
                        <div className="error">{errors.pass}</div>
                    )}

                    <div className="label m-top">Confirm New Password</div>
                    <div className="input-ctn">
                        <img src={lockIcon} alt={''}/>
                        <input className="pass-input"
                               type={showPassword.confirmPass ? "text" : "password"}
                               placeholder="Enter password"
                               id="password"
                               name="password"
                               value={password.confirmPass}
                               onChange={(e) => {
                                   setPassword((prev) => {
                                       return {...prev, confirmPass: e.target.value}
                                   });
                                   setErrors({pass: '', confirmPass: '', msg: ''})
                               }}
                        />
                        <img src={hideEyeLight} alt={''} onClick={() => setShowPassword(prev => {
                            return {...prev, confirmPass: !prev.confirmPass}
                        })}
                             className="hide-icon"/>
                    </div>
                    {errors.confirmPass && (
                        <div className="error">{errors.confirmPass}</div>
                    )}

                    {
                        errors.msg && (<div className="desc m-top">{errors.msg}</div>)
                    }

                    <div className="login-btn m-top" onClick={handleReset}>
                        Reset Password
                    </div>
                </div>
            </div>

            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledResetPassword>
    )
}

export default ResetPassword;