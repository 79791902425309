import styled from "styled-components";


const StyledBilling = styled.div`
    width: 100%;

    .plan-ctn {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }

    .plan-item {
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid grey;
        border-radius: 8px;
        width: auto;
    }

    .plan-item-active {
        border: 2px solid blue;
    }

    .plan-name {
        font-size: 20px;
        font-weight: 600;
    }

    .plan-price {
        font-size: 30px;
        font-weight: 600;
    }
    
    .plan-font {
        font-size: 14px;
        font-weight: 400;
        color: #495A68;
    }

    .plan-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        background: #4484F3;
        color: #FFF;
        padding: 10px 20px;
        border-radius: 8px;
        cursor: pointer;
    }

    .plan-btn:hover {
        opacity: 0.8;
    }
`;

export default StyledBilling;
