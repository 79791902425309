import React, { useEffect, useState } from "react";
import StyledDefine from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/estimate";
import { RootState } from "services/store";
import { toast } from "react-toastify";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import { Keyword } from "services/reducer/estimate";
import awarenessOnImg from "assets/create-estimate/Awareness on-min.png";
import awarenessOffImg from "assets/create-estimate/Awareness off-min.png";
import conversionOnImg from "assets/create-estimate/Conversion on-min.png";
import conversionOffImg from "assets/create-estimate/Conversion off-min.png";

interface Props {
  setHeading: (val: String[]) => void;
}

const Define: React.FunctionComponent<Props> = ({setHeading}) => {
  const estimate = useSelector((state: RootState) => ({
    method: state.estimate.method,
    estimateName: state.estimate.estimateName,
    customKeywords: state.estimate.customKeywords,
    region: state.estimate.region,
    awareness: state.estimate.awareness,
    promoting: state.estimate.promoting,
    audience: state.estimate.audience,
    currEstimateId: state.estimate.currEstimateId,
    updating: state.estimate.updating,
    language: state.estimate.language,
    urls: state.estimate.urls,
  }));

  const country = useSelector((state: RootState) => state.home.country);
  const countryDb = useSelector((state: RootState) => state.home.countryDb);

  interface LanguageObj {
    name: string;
    code: string;
    default_language: boolean;
  }
  const [languages, setLanguages] = useState<Array<LanguageObj>>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initValue, setInitialValue] = useState({
    method: estimate.method,
    estimateName: estimate.estimateName,
    customKeywords: estimate.customKeywords,
    region: estimate.region,
    awareness: estimate.awareness,
    promoting: estimate.promoting,
    audience: estimate.audience,
    updating: false,
    language: estimate.language,
    urls: estimate.urls,
  });

  const initError = {
    promoting: "",
    audience: "",
    keyword: "",
    urls: "",
  };

  useEffect(() => {
    setHeading(["Create", country.charAt(0) + country.substring(1).toLowerCase(), "Define"]);
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/language-list");
      if (res && res.data) {
        setLanguages(res.data);
        if(!estimate.language) {
          const defaultLanguage = res.data?.filter((item:any) => item.default_language)[0]?.code || ''
          dispatch(set({language: defaultLanguage }))
        }
      } else {
        setLanguages([]);
      }
    };
    load();
  }, []);

  const [errors, setErrors] = useState(initError);

  const isChanged = () => {
    return (
      initValue.promoting !== estimate.promoting ||
      initValue.audience !== estimate.audience ||
      initValue.region !== estimate.region ||
      initValue.awareness !== estimate.awareness ||
      initValue.estimateName !== estimate.estimateName ||
      estimate.method !== initValue.method ||
      estimate.customKeywords !== initValue.customKeywords ||
      estimate.language !== initValue.language ||
      estimate.urls !== initValue.urls
    );
  };

  const handleNext = async () => {
    const changed = isChanged();
    // AI GENERATED FLOW
    if (estimate.method == 1) {
      if (!estimate.promoting) {
        setErrors((prev) => {
          return {
            ...initError,
            promoting: "Please enter a valid value for product details",
          };
        });
        return;
      } else if (!estimate.audience) {
        setErrors((prev) => {
          return {
            ...initError,
            t_estimate: "Please enter a valid value for targetted audience",
          };
        });
        return;
      }
      dispatch(set({ activeTab: 2 }));
      navigate("/dashboard/estimate/create/keyword");

      if (!changed) {
        dispatch(set({ updating: true }));
        return;
      } else {
        dispatch(set({ keywordsFetched: false, keywords: [] }));
        if (estimate.updating) {
          const res = await vaticAxiosPost("/estimate/update/keywords", {
            goal: estimate.awareness ? "increase awareness" : "convert",
            target_audience: estimate.audience,
            object_of_interest: estimate.promoting,
            region: estimate.region,
            language: estimate.language,
            estimate_name: estimate.estimateName,
            id: estimate.currEstimateId,
            db: countryDb,
          });
          if (res && res.statusCode == 200) {
            const temp = res.keywords
              .filter((i: Keyword) => i.candi_keyword)
              .map((i: Keyword) => {
                return { ...i, selected: i.score >= 0.35 ? true : false };
              });
            dispatch(
              set({
                currEstimateId: res.estimate_id,
                keywordsFetched: true,
                keywords: temp,
              })
            );
          } else if (res.statusCode == 500) {
            toast.error(
              "The selected language might be inappropriate for the region.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
          }
          return;
        }
        dispatch(
          set({
            updating: true,
            keywordsFetched: false,
            keywords: [],
          })
        );
        const res = await vaticAxiosPost("/estimate/generate/keywords", {
          goal: estimate.awareness ? "increase awareness" : "convert",
          target_audience: estimate.audience,
          object_of_interest: estimate.promoting,
          region: estimate.region,
          estimate_name: estimate.estimateName,
          db: countryDb,
          language: estimate.language,
        });
        if (res && res.statusCode == 200) {
          const temp = res.keywords
            .filter((i: Keyword) => i.candi_keyword)
            .map((i: Keyword) => {
              return { ...i, selected: i.score >= 0.35 ? true : false };
            });
          dispatch(
            set({
              currEstimateId: res.estimate_id,
              keywordsFetched: true,
              keywords: temp,
            })
          );
        } else if (res.statusCode == 500) {
          toast.error(
            "The selected language might be inappropriate for the region.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      }

      // Expert flow
    } else if (estimate.method === 2) {
      if (!estimate.customKeywords) {
        setErrors((prev) => {
          return {
            ...initError,
            keyword: "Please enter a valid value for keywords",
          };
        });
        return;
      }
      const res = await vaticAxiosPost("/estimate/custom/keywords", {
        keywords: estimate.customKeywords,
        region: country,
        estimate_name: estimate.estimateName,
        db: countryDb,
        language: estimate.language,
      });
      if (res) {
        dispatch(set({ currEstimateId: res.estimate_id }));
      }
      handleClose(res.estimate_id);
    } else if (estimate.method === 3) {
      if (!estimate.urls) {
        setErrors((prev) => {
          return {
            ...initError,
            urls: "Please enter list of urls separated by line",
          };
        });
        return;
      }
      const res = await vaticAxiosPost("/estimate/custom_urls", {
        urls: estimate.urls,
        region: country,
        estimate_name: estimate.estimateName,
        db: countryDb,
        language: estimate.language
      });
      if (res) {
        dispatch(set({ currEstimateId: res.estimate_id }));
        handleClose(res.estimate_id);
      }
    }
  };

  const handleClose = async (estimateId: number) => {
    await vaticAxiosPost("/publish_real_time_audience", {
      aud_id: estimateId,
      db: countryDb,
      method: estimate.method,
      keywords: estimate.customKeywords,
      custom_urls: estimate.urls,
    });
    dispatch(unset());
    navigate("/dashboard/estimate");
  };

  return (
    <StyledDefine>
      <div className="define-conatiner">
        {estimate.method === 1 ? (
          <div>
            <div className="input-title">Choose Your Goal.</div>
            <div className="create-method-container">
              <div
                className="rectangle-div"
                onClick={() => {
                  dispatch(set({ awareness: "awareness" }));
                }}
              >
                <div
                  className={
                    estimate.awareness === "awareness" ? "image-container" : ""
                  }
                >
                  <img
                    src={
                      estimate.awareness === "awareness"
                        ? awarenessOnImg
                        : awarenessOffImg
                    }
                    alt=""
                    className="img"
                  />
                </div>
                <div>Awareness</div>
              </div>

              <div
                className="rectangle-div"
                onClick={() => {
                  dispatch(set({ awareness: "conversion" }));
                }}
              >
                <div
                  className={
                    estimate.awareness === "conversion" ? "image-container" : ""
                  }
                >
                  <img
                    src={
                      estimate.awareness === "conversion"
                        ? conversionOnImg
                        : conversionOffImg
                    }
                    alt=""
                    className="img"
                  />
                </div>
                <div>Conversion</div>
              </div>
            </div>

            <div>
              <div className="input-container">
                <div className="input-title">
                  What product or service are you selling?
                </div>
                <textarea
                  className={
                    errors.promoting ? "input-textarea error" : "input-textarea"
                  }
                  placeholder="Microloans / Credit cards / Home loans"
                  value={estimate.promoting}
                  onChange={(e) => dispatch(set({ promoting: e.target.value }))}
                ></textarea>
                {errors.promoting && (
                  <div className="error-msg">{errors.promoting}</div>
                )}
              </div>

              <div className="input-container">
                <div className="input-title">
                  What is your potential target audience?
                </div>
                <textarea
                  className={
                    errors.audience ? "input-textarea error" : "input-textarea"
                  }
                  placeholder="Interested in purchasing / Likely to need
                                  "
                  value={estimate.audience}
                  onChange={(e) => dispatch(set({ audience: e.target.value }))}
                ></textarea>
                {estimate.audience && (
                  <div className="error-msg">{errors.audience}</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            {estimate.method === 2 ? (
              <div>
                <div className="input-container">
                  <div className="input-title">
                    List your Handpicked keywords!
                  </div>
                  <textarea
                    className={
                      errors.keyword ? "input-textarea error" : "input-textarea"
                    }
                    placeholder="microloans, small loans, ..."
                    value={estimate.customKeywords}
                    onChange={(e) =>
                      dispatch(set({ customKeywords: e.target.value }))
                    }
                  ></textarea>
                  {errors.keyword && (
                    <div className="error-msg">{errors.keyword}</div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="input-container">
                  <div className="input-title">List your Handpicked Urls!</div>
                  <textarea
                    className={
                      errors.urls
                        ? "input-textarea error"
                        : "input-textarea custom-url-input"
                    }
                    placeholder={`www.example.com\nabc.com\nsomething.example.com\n...`}
                    value={estimate.urls}
                    onChange={(e) => dispatch(set({ urls: e.target.value }))}
                  ></textarea>
                  {errors.urls && (
                    <div className="error-msg">{errors.urls}</div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div>
                <div className="input-container">
                  <div className="input-title">
                    Select language for your keywords
                  </div>
                  <select
                    className="input-dropdown"
                    value={estimate.language || (languages?.filter(item => item.default_language)[0]?.code || '')}
                    onChange={(e) =>
                      dispatch(set({ language: e.target.value }))
                    }
                  >
                    {languages.map((val, ind) => (
                      <option className="lang-option" value={val.code}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

      <div className="continue-btn-container">
        <div
          className="back-btn"
          onClick={() => {
            dispatch(set({ activeTab: 0 }));
            navigate("/dashboard/estimate/create/method");
          }}
        >
          Back
        </div>
        <div className="continue-btn" onClick={handleNext}>
          {estimate.method === 1 ? "Continue" : "Save Estimate"}
        </div>
      </div>
    </StyledDefine>
  );
};

export default Define;
