import React, { useState } from 'react';
import backImg from "assets/Back.svg";
import { useNavigate } from 'react-router';
import StyledForgot from './styled';
import FullStory from 'react-fullstory';
import {FULLSTORY_ORG_ID, vaticAxiosPost} from "../../../utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import leftArrow from "assets/auth/Arrow_left.png";
import messageIcon from "assets/auth/Message.png";
import {useDispatch} from "react-redux";
import { setVerify } from "../../../services/reducer/signup";
import vatic_logo from "assets/VaticLogo/Vatic Logo_All_Black.svg"

interface ForgotFormValues {
  email: string;
}

const ForgotForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState<ForgotFormValues>({
    email: ''
  });
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrorMsg('');
  };

  const handleSubmit = async () => {

    if (!values.email) {
      setErrorMsg('Please check if your email address is correct.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(values.email)) {
      setErrorMsg('Please check if your email address is correct.');
      return;
    }

    const res = await vaticAxiosPost('/api/auth/v1/reset-password-send', {
      'email': values.email
    });

    if(res.statusCode == 200) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      dispatch(setVerify({verificationType: 2, verify_email: values.email}));
      navigate("/auth/verification");
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <StyledForgot>
      <div className="header">
        <div className="header-inner-ctn">
          <div>
            <img className="header-logo" src={vatic_logo}></img>
          </div>
          <button className="header-signup-btn" onClick={()=>navigate("/auth/signup")}>Sign Up</button>
        </div>
      </div>
        <div className="ctn">
          <div className="inner-ctn">
            <div className="heading">Forgot Password?</div>
            <div className="desc">No worries, enter your email and we will send you reset instructions</div>
            <div className="label m-top">Email Address</div>
            <div className="input-ctn">
              <img src={messageIcon} alt={''}/>
              <input className="email-input"
                     type="email"
                     placeholder="Enter email address"
                     id="email"
                     name="email"
                     value={values.email}
                     onChange={handleChange}
              />
            </div>
            {errorMsg && (
                <div className="error">{errorMsg}</div>
            )}

            <div className="login-btn m-top" onClick={handleSubmit}>
              Reset Password
            </div>

            <div className="desc m-32">Came by mistake or remember your password?</div>

            <div className="back m-top">
               <img src={leftArrow} alt='' className="google-icon"/><span className="forget" onClick={() => navigate("/auth/login")}>Back to Login</span>
            </div>
          </div>
        </div>

        <div>
          <FullStory org={FULLSTORY_ORG_ID || ''}/>
        </div>
      </StyledForgot>
  );
};

export default ForgotForm;
